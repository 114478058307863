export default {
  public: [],
  admin: [
    // 聯繫列表
    {
      path: 'contact',
      name: 'contact',
      component: () => import('@/modules/contact/views/contactList.vue'),
    },

    // 聯繫表單
    {
      path: 'contact/create',
      name: 'contact-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/contact/views/contactForm.vue'),
    },
    {
      path: 'contact/update/:target',
      name: 'contact-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/contact/views/contactForm.vue'),
    },

    {
      path: 'app-config/contact',
      name: 'app-config-contact',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/contact/views/contactAppConfigForm.vue'),
    },
  ],
}
